<template>
  <div>
    <MainLayout>
      <v-dialog v-model="downloadPopup" max-width="570">
        <v-card class="pa-8">
          <div class="object__item">
            В отчёте больше 1000 записей, поэтому он сохранится в реестр
            выгрузок. Перейдите
            <router-link :to="{ name: 'Profile' }" exact
              >на страницу профиля</router-link
            >, чтобы скачать отчёт.
          </div>

          <v-card-actions class="mt-4 d-flex justify-end">
            <v-spacer></v-spacer>
            <v-btn class="btn btn_color_white" @click="downloadPopup = false">
              ОТМЕНА
            </v-btn>
            <v-btn
              :loading="dialog"
              class="btn btn_color_green"
              @click="dowloadPeriodPassLog()"
            >
              ПРОДОЛЖИТЬ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog"
        hide-overlay
        persistent
        width="600"
        height="400"
      >
        <v-card color="#95c23d" dark>
          <v-card-text>
            <div class="title title-loading">
              Ожидайте выгрузки данных
            </div>

            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <TableFilters :title-count="sourceObject.count" :queries="queries">
        <template v-slot:filter-title>
          <div v-if="todayLogsInfo(sourceObject).logsCounts > 0">
            <div
              style="display:flex; flex-direction: row; align-items: center;"
            >
              <div class="mr-10">Проходов: {{ sourceObject.count }}</div>
              <div
                class="mr-4"
                style="display:flex; flex-direction: row; align-items: center;"
              >
                <div class="form__avatar mr-2">
                  <span class="form__avatar-phone"></span>
                </div>
                <div>{{ sourceObject.by_app }}</div>
              </div>
              <div
                class=""
                style="display:flex; flex-direction: row; align-items: center;"
              >
                <div class="form__avatar mr-2">
                  <span class="form__avatar-card"></span>
                </div>
                {{ sourceObject.by_card }}
              </div>
            </div>
          </div>
          <div v-if="todayLogsInfo(sourceObject).logsCounts == 0" class="">
            <span class="title-filter">Проходов: </span>{{ sourceObject.count }}
          </div>
        </template>
        <template v-slot:download-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4"
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        to="users"
        :passlogsUserId="true"
        :source-object="sourceObject"
        :header="header"
      />
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableFilters from "@/components/tables/TableFilters";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import tableColsPassageLog from "@/helpers/tableCols/passageLog";
import moment from "moment";
export default {
  name: "PassageLog",
  components: { TableLayout, TableFilters, MainLayout },
  data() {
    return {
      loadingBtn: false,
      queries: query.passLog,
      downloadPopup: false,
      dialog: false,
      objectId: null // id объекта ДС
    };
  },
  computed: {
    header() {
      return tableColsPassageLog.filter(el => el.text !== "ФИО");
    },
    urlQuery() {
      return {
        query: this.$route.query,
        moment__gte: moment()
          .startOf("day")
          .unix(),
        moment__lte: moment()
          .endOf("day")
          .unix()
      };
    },
    sourceObject() {
      return this.$store.getters.getPassLog;
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query,
        moment__gte: moment()
          .startOf("day")
          .unix(),
        moment__lte: moment()
          .endOf("day")
          .unix(),
        controller__object__organization__diller: [70, 60].includes(
          this.$store.getters.getCurrentRole
        )
          ? this.$store.getters.getCurrentOrganization
          : null,
        controller__object__organization: ![70, 60].includes(
          this.$store.getters.getCurrentRole
        )
          ? this.$store.getters.getCurrentOrganization
          : null
      }
    });
    this.$store.commit("SET_VALUE_SORT", "moment");
    this.isDateLoaded = true;
    this.$store.dispatch("fetchKindergartensPassLog").finally(() => {
      this.isDateLoaded = false;
    });
  },

  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            moment__gte: this.urlQuery.query.moment__gte
              ? this.urlQuery.query.moment__gte
              : moment()
                  .startOf("day")
                  .unix(),
            moment__lte: this.urlQuery.query.moment__lte
              ? this.urlQuery.query.moment__lte
              : moment()
                  .endOf("day")
                  .unix()
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchKindergartensPassLog").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },

  methods: {
    downloadList() {
      if (this.sourceObject.count > 1000) {
        this.downloadPopup = true;
      } else {
        this.loadingBtn = true;
        this.$store
          .dispatch("downloadKindergartensPassLog", this.header)
          .then(() => {
            this.loadingBtn = false;
          });
      }
    },
    dowloadPeriodPassLog() {
      this.dialog = true;
      this.downloadPopup = false;
      let data = {
        moment_lte: this.urlQuery.query.moment__lte,
        moment_gte: this.urlQuery.query.moment__gte,
        id: null
      };
      this.$store.commit("setUserGroupId", localStorage.getItem("groupId"));

      this.$store
        .dispatch("fetchOrganizationObjectsList") // вызываем чтобы получить id объекта ДС
        .then(res => {
          const orgName = this.$store.getters.getDealerInfo.results?.name;
          this.$events.$emit("clear_date-picker");
          this.objectId =
            res.results.filter(el => el?.name == orgName)[0]?.id ||
            res.results[0].id;
        })
        .then(() => {
          data.id = this.objectId;
          this.$store.dispatch("downloadReportsPassLog", { data }).then(() => {
            setTimeout(() => (this.dialog = false), 5000);
          });
        });
    },
    todayLogsInfo(data) {
      let today = moment(new Date()).format("DD.MM.YYYY");
      let filter = data?.results
        .map(function(elem) {
          return {
            data: moment((elem?.moment || 0) * 1000).format("DD.MM.YYYY"),
            device: elem.card_code != null ? "Карта" : "Приложение"
          };
        })
        .filter(function(elem) {
          return elem.data == today;
        });
      let result = {
        logsCounts: filter.length,
        phoneCounts: filter.filter(elem => elem.device == "Приложение").length,
        cardCounts: filter.filter(elem => elem.device == "Карта").length
      };
      return result;
    }
  }
};
</script>

<style scoped>
.form__avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #d0d1d9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.form__avatar-card {
  background-image: url("/img/icons/credit-card.svg");
  width: 20px;
  height: 20px;
  background-size: contain;
}

.form__avatar-phone {
  background-image: url("/img/icons/smartphone.svg");
  width: 20px;
  height: 20px;
  background-size: contain;
}
.title-loading {
  margin-bottom: 16px;
  margin-top: 8px;
  text-align: center;
}
.sub-title {
  margin-left: 4px;
}
</style>
